<template>
  <v-app>
    <v-app-bar app :color="app_bar_color">
      <v-toolbar-title>
        <router-link to="/" style="color: #fff; text-decoration: none;">
          <span class="mr-2">{{ site_name }}</span>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
          bottom
          left
          v-if="this.$store.state.thumb_image_url"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="36">
            <img :src="$store.state.thumb_image_url"  v-bind="attrs"  v-on="on" />
          </v-avatar>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn text @click="logout">
                ログアウト
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>      
    </v-app-bar>

    <v-main>
      <v-container v-if="!this.$store.state.access_token">
        <v-row justify="center" align-content="center">
          <v-col cols="12" md="3">
            <v-alert
                v-if="this.$store.state.errors"
                border="left"
                color="red lighten-2"
                dark
            >
              エラー - {{ this.$store.getters.getErrorMessage }}
            </v-alert>
            <v-card>
              <v-card-title>
                <h2 class="display-1">ログイン</h2>
              </v-card-title>
              <v-card-text class="justify-center" style="text-align: center;">
                <img v-if="this.isInit" :src="require('./assets/img/btn_google_signin_dark_normal_web.png')" @click="login" style="cursor: pointer;" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <router-view v-else />
    </v-main>

    <v-footer
        padless
        class="primary"
        cols="12"
    >
      <v-row
          justify="center"
          no-gutters
      >
        <v-col
            class="primary py-4 text-center white--text"
            cols="12"
        >
          2022&nbsp; -&nbsp;<strong>&nbsp;CXI</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
 import auth_store from './store/auth'
 
 export default {
   name: 'App',
   store: auth_store,
   data() {
     return {
       site_name: `土地マスター(${process.env.VUE_APP_APP_VERSION || 'Beta'})`,
       app_bar_color: process.env.VUE_APP_APP_BAR_COLOR,
       isInit: false,
       errorMessage: null,
     };
   },
   components: {
   },
   created() {
     const checkGauthLoad = setInterval(() => {
       this.isInit = this.$gAuth.isInit;
       if (this.isInit) {
         clearInterval(checkGauthLoad);
       }
     }, 1000);
   },
   mounted(){
     const route = this.$route;
     this.setTitle(route);
   },
   watch: {
     '$route'(route) {
       this.setTitle(route);
     },
   },
   methods: {
     setTitle(route) {
       const setTitle = route.meta.title || this.site_name;
       document.title = setTitle;
     },
     async login() {
       const googleUser = await this.$gAuth.signIn();

       if (!this.$gAuth.isAuthorized) {
         console.log('Error', '!this.$gAuth.isAuthorized');
         this.$store.commit('clear_state');
         return;
       }

       const params = googleUser.getAuthResponse();

       this.axios.get(`/v2/auth/callback`, { params, })
         .then(response => {
           this.$store.commit('change_errors', null);
           this.$store.commit('change_access_token', params.access_token);
           this.$store.commit('change_thumb_image_url', response.data.thumb_image_url);
         })
         .catch(error => {
           console.log(error);

           if (error.response?.status === 500) {
             try {
               auth_store.commit('change_errors', error.response.data.errors);
             } catch (error) {
               auth_store.commit('change_errors', { errors: { message: '不明なエラーです', } });
             }
           }
         });
     },
     async logout() {
       await this.$gAuth.signOut();

       this.$store.commit('clear_state');
       this.axios.post(`/v2/auth/sign_out`)
         .catch(err => {
           this.errorMessage = 'Error:' + err;
           console.log(err);
         });
     },
   },
 };
</script>

<style>
 .container {
     margin: auto !important;
     max-width: none !important;
     width: auto !important;
 }
</style>
